import {
  isLocalhost,
  isCurrentAppDomain,
  getBaseAppUrl,
  getBaseApiUrl,
  getParentBaseUrl,
} from './libs/utils';

function setDefault(origValue: string, defaultValue: string) {
  const origNotDefined = origValue.startsWith('${') && origValue.endsWith('}');
  return origNotDefined ? defaultValue : origValue;
}

const appSuffix = isLocalhost() ? '' : 'op2';
const isAppDomain = isCurrentAppDomain();

const baseAppUrl = getBaseAppUrl();
const ParentBaseUrl = getParentBaseUrl();

const env = {
  urls: {
    api: setDefault(
      'https://api.beta.pysae.com',
      (import.meta.env.VITE_API_URL ?? isLocalhost()) ? '' : getBaseApiUrl(),
    ),
    screen: setDefault(
      'https://app.beta.pysae.com/screen',
      import.meta.env.VITE_SCREEN_URL ?? (isAppDomain ? baseAppUrl : ParentBaseUrl) + '/screen',
    ),
    editor: setDefault(
      'https://app.beta.pysae.com/editor2',
      import.meta.env.VITE_EDITOR_URL ?? (isAppDomain ? baseAppUrl : ParentBaseUrl) + '/editor2',
    ),
    app: setDefault('https://app.beta.pysae.com', import.meta.env.VITE_APP_URL ?? `${baseAppUrl}/${appSuffix}`),
  },
  auth0: {
    domain: setDefault('auth.pysae.com', import.meta.env.VITE_AUTH0_DOMAIN),
    clientId: setDefault('bNKQ2mLRK790NI5SnHuwaDlzArtEtXiQ', import.meta.env.VITE_AUTH0_CLIENT_ID),
    audience: setDefault('https://pysae.com/api', import.meta.env.VITE_AUTH0_AUD),
  },
  datadog: {
    env: setDefault('beta', import.meta.env.VITE_DATADOG_ENV),
  },
  mapbox: {
    apiKey: setDefault('pk.eyJ1IjoicHlzYWUiLCJhIjoiY2s0Y2hrYTlxMG50ODNra2R6ZGVudTR5aiJ9.sccZsmomeJ-zdW21vHcSYQ', import.meta.env.VITE_MAPBOX_API_KEY),
  },
};

export default env;
